import { Col, Image, Row } from 'antd';
import { Diff } from 'features/Diff';
import { FormItemTypes, getFormCheckboxValue, IFormItemConfig } from 'features/Form';
import { createElementP, PlateEditor } from 'features/RictText2';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { objectGet } from 'utils/object';
import { moderateFormItemComponentMap } from '../../Moderate.model';

function getHighlight<T = any>(next: T, prev?: T): string {
  if (!prev) {
    return 'border-4 border-white';
  }
  if (prev !== next) {
    return 'border-4 bg-green-300 border-green-300';
  }
}

export function moderateReviewRenderRichText(next: string, prev?: string) {
  if (!next) {
    return null;
  }
  return prev
    ? <Diff next={next} prev={prev} />
    : <PlateEditor value={next} readOnly />;
}

export function moderateReviewRenderImage(next: string, prev?: string) {
  if (!next) {
    return null;
  }
  return (
    <Image
      src={next}
      preview={false}
      className={getHighlight(next, prev)}
    />
  );
}

export function moderateReviewRenderLink(next: string, prev?: string) {
  if (!next) {
    return null;
  }

  return (
    <Link to={next} target="_blank" className={getHighlight(next, prev)}>
      {next}
    </Link>
  );
}

export function moderateReviewRenderText(next: string, prev?: string) {
  if (!prev) {
    return next;
  }

  return (
    <Diff
      next={[createElementP(next)]}
      prev={[createElementP(prev)]}
    />
  );
}

// TODO @dkchv: !!!!
export function moderateReviewRenderCheckbox(next: boolean, prev?: boolean) {
  const nextValue = getFormCheckboxValue(next);
  const prevValue = getFormCheckboxValue(prev);

  if (prev === undefined) {
    return nextValue;
  }

  return (
    <Diff
      next={[createElementP(nextValue)]}
      prev={[createElementP(nextValue !== prevValue ? '' : prevValue)]}
    />
  );
}

function ModerateReviewItem({ next, prev, config, colSpan }: {
  next: any;
  prev?: any;
  config: IFormItemConfig;
  colSpan: number;
}) {
  const nextValue = useMemo(() => objectGet(next, config.formItemProps.name), [config, next]);
  const prevValue = useMemo(() => objectGet(prev, config.formItemProps.name), [config, prev]);
  const render = useMemo(() => moderateFormItemComponentMap[config.type] || moderateReviewRenderText, [config]);
  const label = useMemo(() => config.type === FormItemTypes.CheckBox
    ? config.inputProps.children
    : config.formItemProps.label, [config]);

  return (
    <>
      <Col span={4}>
        {label}
      </Col>
      {prev && (
        <Col span={colSpan}>
          {render?.(prevValue) || prevValue}
        </Col>
      )}
      <Col span={colSpan}>
        {render(nextValue, prevValue)}
      </Col>
    </>
  );
}

export function ModerateReview({ next, prev, items }: {
  next: any;
  prev?: any;
  items: IFormItemConfig[];
}) {
  const colSpan = useMemo(() => !!(prev) ? 10 : 20, [prev]);

  return (
    <div>
      {/* header */}
      <Row gutter={16} className="mb-6 border-b-2">
        <Col span={4}>Поле</Col>
        {prev && (
          <Col span={10}>Старое значение</Col>
        )}
        <Col span={10}>Новое значение</Col>
      </Row>

      {/* content */}
      {items.map((item, index) => (
        <Row gutter={16} key={index} className="mb-6">
          <ModerateReviewItem
            next={next}
            prev={prev}
            config={item}
            colSpan={colSpan}
          />
        </Row>
      ))}
    </div>
  );
}
