import { DeleteOutlined, EditOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useLayoutConfirm } from 'features/Layout';
import { MouseEventHandler, useMemo } from 'react';
import { IModerateable, ModerateStatusTypes } from '../../Moderate.interface';

export function ModerateDraftActionList({ status, onSave, onStartModerate, onRemove, onRemoveDraft }: {
  status: IModerateable['status'];
  onSave: MouseEventHandler<HTMLElement>;
  onStartModerate: MouseEventHandler<HTMLElement>;
  onRemove: MouseEventHandler<HTMLElement>;
  onRemoveDraft: MouseEventHandler<HTMLElement>;
}) {
  const handleOnModerateClick = useLayoutConfirm('Отправить на модерацию?', onStartModerate);
  const handleOnRemoveDraft = useLayoutConfirm('Удалить черновик?', onRemoveDraft);
  const handleOnRemovePublished = useLayoutConfirm('Удалить публикацию?', onRemove);

  const removeDraft = useMemo(() => (
    <Button
      key="removeDraft"
      className="ml-4"
      onClick={handleOnRemoveDraft}
      icon={<DeleteOutlined />}
      danger
    >
      Удалить черновик
    </Button>
  ), [handleOnRemoveDraft]);

  const removePublished = useMemo(() => (
    <Button
      key="removePublished"
      className="ml-4"
      onClick={handleOnRemovePublished}
      icon={<DeleteOutlined />}
      danger
    >
      Удалить публикацию
    </Button>
  ), [handleOnRemovePublished]);

  const createDraft = useMemo(() => (
    <Button
      type="primary"
      key="createDraft"
      onClick={onSave}
      icon={<PlusOutlined />}
    >
      Создать черновик
    </Button>
  ), [onSave]);

  const startModeration = useMemo(() => (
    <Button
      key="startModeration"
      onClick={handleOnModerateClick}
      icon={<SendOutlined />}
    >
      Отправить на модерацию
    </Button>
  ), [handleOnModerateClick]);

  const saveDraft = useMemo(() => (
    <Button
      key="saveDraft"
      type="primary"
      onClick={onSave}
      icon={<EditOutlined />}
    >
      Сохранить черновик
    </Button>
  ), [onSave]);

  const list = useMemo(() => {
    switch (status) {
      case ModerateStatusTypes.New:
      case ModerateStatusTypes.Draft:
        return [saveDraft, startModeration, removeDraft];
      case ModerateStatusTypes.Published:
        return [createDraft, removePublished];
      default:
        return [createDraft];
    }
  }, [createDraft, removeDraft, removePublished, saveDraft, startModeration, status]);

  return (
    <Space>
      {list}
    </Space>
  );
}
