import { App, FormInstance } from 'antd';
import { FormCommonError, onFormFinishFunc } from 'features/Form';
import { MouseEventHandler, ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModerateDraftActionList } from './components/ModerateActions/ModerateActions';
import { ModerateStatusTypes } from './Moderate.interface';

export function useModerateFormActions<T>(status: ModerateStatusTypes, form: FormInstance<T>, onFinish: Function, onRemove: MouseEventHandler<HTMLElement>, onRemoveDraft: MouseEventHandler<HTMLElement>): [ReactElement, onFormFinishFunc<T>] {
  const [isStartModeration, setIsStartModeration] = useState(false);

  const handleSave = useCallback(() => {
    form.submit();
  }, [form]);

  const handleStartModerate = useCallback(() => {
    setIsStartModeration(true);
    handleSave();
  }, [handleSave]);

  const actions = useMemo(() => (
    <ModerateDraftActionList
      status={status}
      onSave={handleSave}
      onStartModerate={handleStartModerate}
      onRemove={onRemove}
      onRemoveDraft={onRemoveDraft}
    />
  ), [handleSave, handleStartModerate, onRemove, onRemoveDraft, status]);

  const handleFinish = useCallback(async (values: T) => {
    try {
      await onFinish(values, isStartModeration);
    } catch (e) {
      setIsStartModeration(false);
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [isStartModeration, onFinish]);

  return [actions, handleFinish];
}

export function useModerateOnFinish<T>(onFinish: Function, onStartModeration: Function, backUrl: string, labelSuccess: string) {
  const { message } = App.useApp();
  const navigate = useNavigate();

  return useCallback(async (values: T, isStartModeration: boolean) => {
    await onFinish(values);

    if (isStartModeration) {
      await onStartModeration();
    }

    message.success(isStartModeration ? 'Отправлено на модерацию' : labelSuccess);
    navigate(backUrl);
  }, [labelSuccess, message, navigate, onFinish, onStartModeration, backUrl]);
}
