import { Tabs, TabsProps } from 'antd';
import { useCallback } from 'react';
import { useSearchParams } from 'utils/url';

interface UITabsSearchParams {
  [key: string]: string;
}

const UI_TABS_DEFAULT_SEARCH_KEY = 'tab';

// antd tabs with subscription to search params (defaults to 'tab')
export function UITabs({ searchKey = UI_TABS_DEFAULT_SEARCH_KEY, defaultActiveKey, onChange, ...props }: {
  searchKey?: string | false;
} & TabsProps) {
  const [params, setParams] = useSearchParams<UITabsSearchParams>();

  const handleChange = useCallback((value: string) => {
    if (searchKey) {
      setParams((prev) => {
        prev.set(searchKey, value);
        return prev;
      });
    }
    onChange?.(value);
  }, [onChange, searchKey, setParams]);

  return (
    <Tabs
      defaultActiveKey={searchKey
        ? params[searchKey] || defaultActiveKey
        : defaultActiveKey}
      onChange={handleChange}
      {...props}
    />
  );
}
