import { Form } from 'antd';
import { FormCommonError } from 'features/Form';
import { IModerateFormComponentProps } from 'features/Moderate';
import { useCallback } from 'react';
import { createSlug } from 'utils/string';
import { ModerateFormDraft } from '../../../Moderate/components/ModerateForm/ModerateFormDraft';
import { IServiceComplex } from '../../Service.interface';

export function ServiceComplexBasicForm({ model, onFinish, ...props }: IModerateFormComponentProps<IServiceComplex>) {
  const [form] = Form.useForm<IServiceComplex>();

  const handleFinish = useCallback(async (values: IServiceComplex, isStartModeration: boolean) => {
    const slug = model?.slug || createSlug(values.info.title);

    try {
      await onFinish({
        ...model,
        ...values,
        slug,
        info: {
          ...model?.info,
          ...values.info,
        },
      }, isStartModeration);
    } catch (e) {
      console.error('form error', e);
      throw new FormCommonError();
    }
  }, [model, onFinish]);

  return (
    <ModerateFormDraft<IServiceComplex>
      form={form}
      model={model}
      onFinish={handleFinish}
      {...props}
    />
  );
}
