import { createPluginFactory, isInline } from '@udecode/plate-common';
import React from 'react';
import { describeUpdate, diffOperationColors, MARK_DIFF } from './Diff.model';
import { DiffOperation, withGetFragmentExcludeDiff } from './plate-diff-override';

export const createDiffPlugin = createPluginFactory({
  inject: {
    aboveComponent: () => ({ children, editor, element }) => {
      if (!element.diff) return children;

      const diffOperation = element.diffOperation as DiffOperation;

      const label = {
        delete: 'deletion',
        insert: 'insertion',
        update: 'update',
      }[diffOperation.type];

      const Component = isInline(editor, element) ? 'span' : 'div';

      return (
        <Component
          aria-label={label}
          className={diffOperationColors[diffOperation.type]}
          title={
            diffOperation.type === 'update'
              ? describeUpdate(diffOperation)
              : undefined
          }
        >
          {children}
        </Component>
      );
    },
  },
  isLeaf: true,
  key: MARK_DIFF,
  withOverrides: withGetFragmentExcludeDiff,
});
