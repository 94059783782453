import { useCallback } from 'react';
import { FormCommonError, FormCommonRemoveError } from './Form.model';

function useFormAction(ActionError: any, onAction?: Function | false) {
  return useCallback(async () => {
    if (!onAction) {
      return;
    }
    try {
      await onAction();
    } catch (e) {
      console.error('form error', e);
      throw new ActionError();
    }
  }, [ActionError, onAction]);
}

export function useFormActionCommon(onAction?: Function | false) {
  return useFormAction(FormCommonError, onAction);
}

export function useFormActionRemove(onRemove?: Function | false) {
  return useFormAction(FormCommonRemoveError, onRemove);
}
