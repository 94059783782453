import { useAuthRoles } from 'features/Auth';
import { useMemo } from 'react';
import { useDirectorateIsAdmin, useDirectorateListWithRoles } from '../Directorate/Directorate.hook';
import { useStationListLoadQuery } from './Station.api';
import { getStationRoleAdmin, STATION_ROLE_PREFIX } from './Station.model';

export function useStationRoles() {
  return useAuthRoles(STATION_ROLE_PREFIX);
}

export function useStationListWithRoles() {
  const { data = [], isLoading: isStationListLoading } = useStationListLoadQuery();
  const isAdmin = useDirectorateIsAdmin();
  const stationRoles = useStationRoles();
  const { data: directorateList, isLoading: isDirectorateListLoading } = useDirectorateListWithRoles();

  const filtered = useMemo(() => {
    if (isAdmin) {
      return data;
    }
    const directorateSlugList = directorateList.map((item) => item.slug);
    return data.filter((item) => directorateSlugList.includes(item.directorate_slug) || stationRoles.includes(getStationRoleAdmin(item.slug)));
  }, [data, directorateList, isAdmin, stationRoles]);

  return {
    data: filtered,
    isLoading: isStationListLoading || isDirectorateListLoading,
  };
}
