import { TreeSelect } from 'antd';
import { useMemo } from 'react';
import { usePageListLoadQuery } from '../../Page.api';
import { PageViewTypes } from '../../Page.interface';

export function PageSelector({ value, pageSlug, onChange }: {
  value?: string;
  pageSlug?: string;
  onChange?: (value: string) => any;
}) {
  const { data = [], isLoading } = usePageListLoadQuery(PageViewTypes.Page);

  const tree = useMemo(() => {
    return data.map((item) => ({
      id: item.slug,
      pId: item.parent,
      title: item.info.title,
      value: item.slug,
      disabled: item.slug === pageSlug,
    }));
  }, [data, pageSlug]);

  return (
    <TreeSelect
      value={value}
      treeData={tree}
      treeDataSimpleMode
      loading={isLoading}
      onChange={onChange}
      treeDefaultExpandAll
    />
  );
}
