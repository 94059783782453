import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import type { UseParamsBase } from 'features/Router';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServiceItemCreate, useServiceItemInfoUpdate } from '../../Service.hook';
import { IServiceBase, ServiceType } from '../../Service.interface';
import { serviceComplexFormItems, serviceCRUDMap, serviceEditUrl, serviceFormBackUrl } from '../../Service.model';
import { ServiceComplexBasicForm } from '../ServiceComplexBasicForm/ServiceComplexBasicForm';

interface ServiceCreateParams extends UseParamsBase {
  serviceType: ServiceType;
}

export function ServiceComplexCreate() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { serviceType } = useParams<ServiceCreateParams>();
  const config = useMemo(() => serviceCRUDMap[serviceType], [serviceType]);
  const [serviceCreate] = useServiceItemCreate(serviceType);
  const [serviceInfoUpdate] = useServiceItemInfoUpdate(serviceType);

  const handleFinish = useCallback(async (values: IServiceBase) => {
    const { info, ...base } = values;
    // create service
    const result = await serviceCreate(base).unwrap();
    // update info
    await serviceInfoUpdate({
      slug: result.slug,
      lang: LANGUAGE_DEFAULT,
      ...info,
    }).unwrap();

    message.success(config.labelCreateSuccess);
    navigate(serviceEditUrl(serviceType, result.slug, result.status));
  }, [serviceCreate, serviceInfoUpdate, message, config, navigate, serviceType]);

  return (
    <LayoutContent
      title={config.labelCreateTitle}
      backUrl={serviceFormBackUrl(serviceType)}
    >
      <ServiceComplexBasicForm
        items={serviceComplexFormItems}
        onFinish={handleFinish}
      />
    </LayoutContent>
  );
}
