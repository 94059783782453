import type { TDescendant, Value } from '@udecode/plate-common';
import { createPlateEditor } from '@udecode/plate-common';
import { PlateEditor, richTextParse } from 'features/RictText2';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import { plugins } from '../RictText2/RichText2.plugins';
import { computeDiff } from './plate-diff-override';

export function Diff({ next, prev }: {
  next: string | TDescendant[];
  prev: string | TDescendant[];
}) {
  const diffValue = useMemo(() => {
    const nextValue = richTextParse(next);
    const prevValue = richTextParse(prev);
    const editor = createPlateEditor({ plugins });

    return computeDiff(prevValue, cloneDeep(nextValue), {
      isInline: editor.isInline,
      lineBreakChar: '¶',
    }) as Value;
  }, [next, prev]);

  return <PlateEditor value={diffValue} readOnly />;
}
