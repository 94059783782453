import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { contactSerialize } from '../../../Contact/Contact.model';
import { StationServiceBasicForm } from '../../../StationService/components/StationServiceBasicForm/StationServiceBasicForm';
import { useStationServiceCreateMutation, useStationServiceInfoUpdateMutation } from '../../../StationService/StationService.api';
import { IStationServiceForm, StationServiceEditParams } from '../../../StationService/StationService.intefrace';
import { useServiceItemLoad } from '../../Service.hook';
import { serviceStationFormBackUrl } from '../../Service.model';

export function ServiceStationServiceCreate() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { serviceType, serviceSlug, serviceStatus } = useParams<StationServiceEditParams>();
  const { data: service } = useServiceItemLoad(serviceType, serviceSlug);
  const [stationServiceCreate] = useStationServiceCreateMutation();
  const [stationServiceInfoUpdate] = useStationServiceInfoUpdateMutation();
  const backUrl = useMemo(() => serviceStationFormBackUrl(serviceType, serviceSlug, serviceStatus), [serviceSlug, serviceType, serviceStatus]);

  const handleFinish = useCallback(async (values: IStationServiceForm, attachments: string[]) => {
    const result = await stationServiceCreate({
      station_id: values.stationId,
      service_id: service.id,
      external_link: values.externalLink,
      type: serviceType,
      attachments,
    }).unwrap();

    await stationServiceInfoUpdate({
      serviceId: result.id,
      lang: LANGUAGE_DEFAULT,
      content: values.content,
      title: values.title,
      contacts: contactSerialize(values.contacts),
    }).unwrap();

    message.success('Вокзал добавлен');
    navigate(backUrl);
  }, [stationServiceCreate, service, serviceType, stationServiceInfoUpdate, message, navigate, backUrl]);

  return (
    <LayoutContent
      title="Добавить вокзал к услуге"
      backUrl={backUrl}
      isLoading={!service}
    >
      <StationServiceBasicForm
        serviceType={serviceType}
        serviceStatus={serviceStatus}
        service={service}
        onFinish={handleFinish}
      />
    </LayoutContent>
  );
}
