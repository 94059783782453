import { Button } from 'antd';
import { LayoutContent } from 'features/Layout';
import { UITable } from 'features/UI';
import { useDirectorateIsAdmin } from '../../../Directorate/Directorate.hook';
import { useStationListWithRoles } from '../../Station.hooks';
import { stationTableColumnList, stationTableFilter } from '../../Station.model';

export function StationTable() {
  const { data = [], isLoading } = useStationListWithRoles();
  const isCreate = useDirectorateIsAdmin();

  return (
    <LayoutContent title="Вокзалы">
      <UITable
        createButton={isCreate && (
          <Button href="/stations/create" className="grow-0">
            Создать вокзал
          </Button>
        )}
        filter={stationTableFilter}
        loading={isLoading}
        columns={stationTableColumnList}
        dataSource={data}
        pagination={false}
      />
    </LayoutContent>
  );
}
