import { Collapse, CollapseProps, Form } from 'antd';
import { IModerateFormComponentProps } from 'features/Moderate';
import { ServiceType } from 'features/Service';
import { useCallback, useMemo } from 'react';
import { createSlug } from 'utils/string';
import { ModerateFormDraft } from '../../../Moderate/components/ModerateForm/ModerateFormDraft';
import { ServiceStationList } from '../../../Service/components/ServiceStationList/ServiceStationList';
import { useServiceIsAdmin } from '../../../Service/Service.hook';
import { ISimple } from '../../Simple.interface';

export function SimpleForm({ model, onFinish, onRemove, ...props }: IModerateFormComponentProps<ISimple>) {
  const [form] = Form.useForm<ISimple>();
  const isAdmin = useServiceIsAdmin(ServiceType.Simple);

  const stationListContent = useMemo((): CollapseProps['items'] => !model ? [] : [{
    key: 1,
    label: `Связанные вокзалы`,
    children: (
      <ServiceStationList
        serviceType={ServiceType.Simple}
        serviceId={model?.id}
      />
    ),
  }], [model]);

  const handleFinish = useCallback(async (values: Partial<ISimple>, isStartModeration: boolean) => {
    const slug = model?.slug || createSlug(values.info.title);

    await onFinish({
      ...model,
      ...values,
      slug,
      info: {
        ...model?.info,
        ...values.info,
      },
    }, isStartModeration);
  }, [model, onFinish]);

  return (
    <ModerateFormDraft<ISimple>
      form={form}
      model={model}
      onFinish={handleFinish}
      onRemove={isAdmin && model && onRemove}
      after={model && (
        <Collapse
          key="list"
          items={stationListContent}
        />
      )}
      {...props}
    />
  );
}
