import { FormForm, IFormFormProps } from 'features/Form';
import { useModerateFormActions } from '../../Moderate.hook';
import { IModerateFormComponentProps } from '../../Moderate.interface';

export function ModerateFormDraft<T>({ form, model, status, onFinish, onRemove, onRemoveDraft, ...props }: IModerateFormComponentProps<T> & Omit<IFormFormProps<T>, 'onFinish' | 'initialValues'>) {
  const [actions, onModerateFinish] = useModerateFormActions(status, form, onFinish, onRemove, onRemoveDraft);

  return (
    <FormForm
      initialValues={model as Partial<T>}
      form={form}
      onFinish={onModerateFinish}
      actions={actions}
      {...props}
    />
  );
}
