import { ExportOutlined, LockTwoTone } from '@ant-design/icons';
import { Form, Space, Typography } from 'antd';
import { FormCommonError, FormForm, formRuleRequired } from 'features/Form';
import { PlateEditor, richText2SerializeContent } from 'features/RictText2';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IStationService, IStationServiceSimple, IStationServiceSimpleForm } from '../../StationService.intefrace';
import { StationServiceSimpleSelector } from '../StationServiceSimpleSelector/StationServiceSimpleSelector';

export function StationServiceSimpleForm({ model, stationService, onFinish, onRemove }: {
  model?: IStationServiceSimple;
  stationService: IStationService;
  onFinish: (values: IStationServiceSimpleForm) => any;
  onRemove?: () => any;
}) {
  const [form] = Form.useForm<IStationServiceSimpleForm>();

  const initialValues = useMemo((): IStationServiceSimpleForm => {
    if (!model) {
      return {
        content: '',
        simpleId: null,
      };
    }
    return {
      content: model.info.content,
      simpleId: model.simple_service_id,
    };
  }, [model]);

  const handleFinish = useCallback(async (values: IStationServiceSimpleForm) => {
    const content = await richText2SerializeContent('station-service', values.content);
    try {
      await onFinish({
        ...values,
        content,
      });
    } catch (e) {
      throw new FormCommonError();
    }
  }, [onFinish]);

  return (
    <FormForm
      initialValues={initialValues}
      form={form}
      onFinish={handleFinish}
      onRemove={model && onRemove}
    >
      {model
        ? (
          <div className="mb-6">
            <Space>
              <Typography.Text>Простая:</Typography.Text>
              <Typography.Text italic>
                {model.service_base_info.info.title}
              </Typography.Text>
              <LockTwoTone />
              <Link to={`/services/simple/${model.service_base_info.slug}`}>
                <ExportOutlined />
              </Link>
            </Space>
          </div>
        )
        : (
          <Form.Item
            label="Услуга"
            name="simpleId"
            rules={[formRuleRequired('Выберите услугу')]}
            validateTrigger="onBlur"
          >
            <StationServiceSimpleSelector stationService={stationService} />
          </Form.Item>
        )}

      <div className="mb-6">
        <Space>
          <Typography.Text>Флагманская:</Typography.Text>
          <Typography.Text italic>
            {stationService.info.title}
          </Typography.Text>
          <LockTwoTone />
        </Space>
      </div>

      <Form.Item
        label="Описание"
        name="content"
      >
        <PlateEditor />
      </Form.Item>

    </FormForm>
  );
}
