import { IModerateFormModerateProps } from '../../Moderate.interface';
import { isModerateStatusOnModeration } from '../../Moderate.model';
import { ModerateStatus } from '../ModerateStatus/ModerateStatus';
import { ModerateFormOnModeration } from './ModerateFormOnModeration';

export function ModerateForm<T>({ model, items, component: Form, status, onFinish, onRemove, onRemoveDraft, ...moderationProps }: IModerateFormModerateProps<T>) {
  return (
    <div>
      <div className="flex gap-3 mb-9">
        <span>Статус:</span>
        <ModerateStatus status={status} />
      </div>

      {isModerateStatusOnModeration(status)
        ? (
          <ModerateFormOnModeration
            next={model}
            status={status}
            items={items}
            onRemoveDraft={onRemoveDraft}
            {...moderationProps}
          />
        )
        : (
          <Form
            model={model}
            status={status}
            items={items}
            onFinish={onFinish}
            onRemove={onRemove}
            onRemoveDraft={onRemoveDraft}
          />
        )}
    </div>
  );
}
