export const APP_API_ADMIN_URL = `${process.env.REACT_APP_API_ADMIN_URL}/admin`;
export const APP_PUBLIC_URL = String(process.env.REACT_APP_PUBLIC_URL);
export const APP_API_PUBLIC_URL = `${APP_PUBLIC_URL}/public`;
export const APP_AUTH_URL = String(process.env.REACT_APP_AUTH_URL);

const APP_ENV = String(process.env.REACT_APP_ENV);

export const isLocal = APP_ENV === 'local';

export enum AppApiDevUrl {
  Station = 'http://localhost:8086/admin',
  Service = 'http://localhost:8084/admin',
  News = 'http://localhost:8088/admin',
}

export const featureFlags: Record<string, boolean> = {
  isStationDev: false,
  isServiceDev: false,
  isNewsDev: false,
  isDev: false,
  isDevRoleList: false,
};
