import { Form, Input, Select } from 'antd';
import { createFormList, FormListItemModeTypes, FormListItemProps } from 'features/Form';
import { ContactBlock, ContactBlockTypes } from '../../Contact.interface';
import { contactBlockOptions } from '../../Contact.model';

function ContactItem({ field }: FormListItemProps) {
  return (
    <div className="flex gap-2 grow">
      <Form.Item name={[field.name, 'type']} className="w-1/3 mb-0">
        <Select options={contactBlockOptions} />
      </Form.Item>
      <Form.Item name={[field.name, 'text']} className="w-1/3 mb-0">
        <Input.TextArea placeholder="Контакт" />
      </Form.Item>
      <Form.Item name={[field.name, 'details']} className="w-1/3 mb-0">
        <Input.TextArea placeholder="Описание" />
      </Form.Item>
    </div>
  );
}

export const createContactList = createFormList<ContactBlock>(ContactItem, {
  addLabel: 'Добавить контакт',
  mode: FormListItemModeTypes.Inline,
  model: {
    type: ContactBlockTypes.Address,
    text: '',
  },
});
