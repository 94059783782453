import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { getModerateState, useModerateOnFinish, UseModerateParams } from 'features/Moderate';
import { UseParamsBase } from 'features/Router';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyStationServiceListLoadQuery, useStationServiceRemoveMutation } from '../../../StationService/StationService.api';
import { useServiceIsAdmin, useServiceItemApprove, useServiceItemDecline, useServiceItemInfoUpdate, useServiceItemLoad, useServiceItemRemove, useServiceItemStartModeration, useServiceItemUpdate, useServicePrevLoad, useServiceRemoveDraft } from '../../Service.hook';
import { IServiceBase, ServiceType } from '../../Service.interface';
import { serviceCRUDMap, serviceFormBackUrl } from '../../Service.model';
import { ServiceComplexForm } from '../ServiceComplexForm/ServiceComplexForm';

interface ServiceComplexEditParams extends UseParamsBase, UseModerateParams {
  serviceType: ServiceType;
  serviceSlug: string;
}

export function ServiceComplexEdit() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { serviceSlug, serviceType, status } = useParams<ServiceComplexEditParams>();
  const { data, isLoading } = useServiceItemLoad(serviceType, serviceSlug, getModerateState(status));
  const [serviceInfoUpdate] = useServiceItemInfoUpdate(serviceType);
  const [serviceUpdate] = useServiceItemUpdate(serviceType);
  const [serviceRemove] = useServiceItemRemove(serviceType);
  const backUrl = useMemo(() => serviceFormBackUrl(serviceType), [serviceType]);
  const config = useMemo(() => serviceCRUDMap[serviceType], [serviceType]);
  const [stationServiceListLoad] = useLazyStationServiceListLoadQuery();
  const [stationServiceRemove] = useStationServiceRemoveMutation();
  const [serviceStartModeration] = useServiceItemStartModeration(serviceType);
  const [serviceApprove] = useServiceItemApprove(serviceType);
  const [serviceDecline] = useServiceItemDecline(serviceType);
  const [servicePrevLoad] = useServicePrevLoad(serviceType);
  const [serviceRemoveDraft] = useServiceRemoveDraft(serviceType);
  const isAdmin = useServiceIsAdmin(serviceType);

  const handleFinish = useCallback(async (values: IServiceBase) => {
    const { info, ...base } = values;

    await Promise.all([
      serviceUpdate(base).unwrap(),
      serviceInfoUpdate({
        slug: serviceSlug,
        lang: LANGUAGE_DEFAULT,
        ...info,
      }).unwrap(),
    ]);
  }, [serviceUpdate, serviceInfoUpdate, serviceSlug]);

  const handleOnStartModeration = useCallback(() => {
    return serviceStartModeration(data.slug).unwrap();
  }, [data, serviceStartModeration]);

  const handleModerateFinish = useModerateOnFinish(handleFinish, handleOnStartModeration, backUrl, config.labelEditSuccess);

  const handleRemove = useCallback(async () => {
    // remove related services on stations
    const stationServiceList = await stationServiceListLoad({
      service_id: data.id,
      service_type: serviceType,
    }).unwrap();
    const promises = stationServiceList.map((item) => stationServiceRemove(item.id).unwrap());
    await Promise.all(promises);
    // remove service
    await serviceRemove(serviceSlug).unwrap();
    navigate(backUrl);
  }, [stationServiceListLoad, data, serviceType, serviceRemove, serviceSlug, navigate, backUrl, stationServiceRemove]);

  const handleApprove = useCallback(async () => {
    await serviceApprove(data.slug).unwrap();
    message.success('Изменения приняты');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, serviceApprove]);

  const handleDecline = useCallback(async () => {
    await serviceDecline(data.slug).unwrap();
    message.success('Изменения отклонены');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, serviceDecline]);

  const handleRemoveDraft = useCallback(async () => {
    await serviceRemoveDraft(data.slug).unwrap();
    message.success('Черновик удален');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, serviceRemoveDraft]);

  const handlePrevLoad = useCallback(() => {
    return servicePrevLoad({
      slug: serviceSlug,
      is_draft: false,
    });
  }, [servicePrevLoad, serviceSlug]);

  return (
    <LayoutContent
      title={config.labelEditTitle}
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <ServiceComplexForm
          status={status}
          serviceType={serviceType}
          model={data}
          onFinish={handleModerateFinish}
          onRemove={isAdmin && handleRemove}
          onRemoveDraft={handleRemoveDraft}
          onApprove={handleApprove}
          onDecline={handleDecline}
          onPrevLoad={handlePrevLoad}
        />
      )}
    />
  );
}
