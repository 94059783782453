import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePageInfoUpdateMutation, usePageLoadQuery, usePageRemoveMutation, usePageUpdateMutation } from '../../Page.api';
import { usePageConfig } from '../../Page.hooks';
import { IPageFormBase, IPageFormInfo, PagePageEditParams } from '../../Page.interface';
import { pageTableUrl } from '../../Page.model';

export function PageEdit() {
  const { slug, type } = useParams<PagePageEditParams>();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { data, isLoading } = usePageLoadQuery(slug);
  const backUrl = useMemo(() => pageTableUrl(type), [type]);
  const [pageUpdate] = usePageUpdateMutation();
  const [pageInfoUpdate] = usePageInfoUpdateMutation();
  const [pageRemove] = usePageRemoveMutation();
  const [config, PageForm] = usePageConfig(type);

  const handleFinish = useCallback(async (base: IPageFormBase, info: IPageFormInfo) => {
    await pageUpdate({
      slug,
      template: base.template,
      link: base.link,
      parent: base.parent,
    }).unwrap();
    await pageInfoUpdate({
      view_type: type,
      lang: LANGUAGE_DEFAULT,
      slug,
      attachments: base.attachments,
      ...info,
    }).unwrap();
    message.success(config.updateSuccess);
    navigate(backUrl);
  }, [backUrl, config, message, navigate, pageInfoUpdate, pageUpdate, slug, type]);

  const handleRemove = useCallback(async () => {
    await pageRemove({
      slug: data.slug,
      view_type: type,
    }).unwrap();
    message.success(config.removeSuccess);
    navigate(backUrl);
  }, [backUrl, data, message, navigate, pageRemove, type, config]);

  return (
    <LayoutContent
      title={config.editLabel}
      backUrl={backUrl}
      isLoading={isLoading}
      render={() => (
        <PageForm
          model={data}
          templateOptions={config.formTemplateOptions}
          onFinish={handleFinish}
          onRemove={handleRemove}
        />
      )}
    />
  );
}
