import { APP_API_ADMIN_URL } from 'App.environment';
import { ClientFetchService } from 'features/Client';
import { getFileSalt } from 'utils/file';

interface MediaUploadResponse {
  link: string;
}

/**
 * @default rework with rtk?
 */
export const mediaClient = new ClientFetchService(`${APP_API_ADMIN_URL}/media/api/v1`);

export async function clientMediaUpload(prefix: string, file: File): Promise<string> {
  const formData = new FormData();
  formData.append('file', file, `${prefix}-${getFileSalt()}-${file.name}`);
  const result = await mediaClient.post<MediaUploadResponse>('/upload', formData);
  return result.link;
}
