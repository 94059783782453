import { RouteProps } from 'react-router-dom';
import { SimpleCreate } from './components/SimpleCreate/SimpleCreate';
import { SimpleEdit } from './components/SimpleEdit/SimpleEdit';

export const simpleRouter: RouteProps[] = [
  {
    path: '/services/simple/create',
    Component: SimpleCreate,
  },
  {
    path: '/services/simple/:slug/:status',
    Component: SimpleEdit,
  },
];
