import { IFormSelectOption } from 'features/Form';
import { ContactBlockTypes, ContactGroups } from './Contact.interface';

export const contactBlockOptions: IFormSelectOption[] = [
  {
    label: 'Текст',
    value: ContactBlockTypes.Text,
  },
  {
    label: 'Адрес',
    value: ContactBlockTypes.Address,
  },
  {
    label: 'E-mail',
    value: ContactBlockTypes.Email,
  },
  {
    label: 'Телефон',
    value: ContactBlockTypes.Phone,
  },
  {
    label: 'Ссылка',
    value: ContactBlockTypes.Link,
  },
];

export function contactParse(value: string): ContactGroups[] {
  try {
    return JSON.parse(value) || [];
  } catch (e) {
    console.error("--error parsing contacts", e);
    return [];
  }
}

export function contactSerialize(value: ContactGroups[]): string {
  try {
    return JSON.stringify(value)
  } catch (e) {
    console.error("--error serialize contacts", e);
    return '';
  }
}
