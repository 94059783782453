import { ONE_DAY_IN_MS } from './date';

type OnLoadFunc = (url: string, name?: string) => any;

export function fileImageToBase64static(file: File, onLoad?: OnLoadFunc) {
  const [mime] = file.type.split('/');
  if (mime !== 'image') {
    return;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const url = String(reader.result);
    onLoad?.(url, file.name);
  };
}

export function fileImageToBase64(file: File, onLoad?: OnLoadFunc): Promise<string> {
  return new Promise((resolve, reject) => {
    const [mime] = file.type.split('/');
    if (mime !== 'image') {
      reject();
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const url = String(reader.result);
      onLoad?.(url);
      resolve(String(url));
    };
    reader.onerror = reject;
  });
}

export function getFileSalt(): string {
  const now = (new Date()).getTime();
  return String(now % ONE_DAY_IN_MS);
}

export function fileBase64ToFile(base64String: string, fileName: string) {
  if (!Boolean(base64String)) {
    return false;
  }

  try {
    const [mimeType, data] = base64String.split(',');
    const decodedData = atob(data);
    const content = new Uint8Array(decodedData.length);

    Array.from(decodedData).forEach((char, index) => {
      content[index] = char.charCodeAt(0);
    });

    return new File([content], fileName, { type: mimeType });
  } catch (e) {
    console.log(e);
    return false;
  }
}
