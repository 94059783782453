export const EmptyValues: Array<any> = [undefined, null, ''];

export function objectUpdateDiff<T = Object>(a: T, b: T): Partial<T> {
  const res = {};
  Object.keys(a).forEach((k) => {
    if (a[k] === b[k]) {
      return;
    }
    if (Array.isArray(a[k])) {
      //  are arrays equal?
      if (Array.isArray(b[k]) && a[k].length === b[k].length && (a[k] as Array<any>).every((item, index) => b[k][index] === item)) {
        return;
      }
      res[k] = b[k];
      return;
    }

    res[k] = (!EmptyValues.includes(a[k]) && typeof a[k] === 'object')
      ? objectUpdateDiff(a[k], b[k])
      : b[k];
  });
  return res;
}

export function objectIsEmpty(a: Object): boolean {
  return Object.keys(a).length === 0;
}

export function objectFlatValuesList<T>(object: Record<any, T[]>): T[] {
  return Object
    .values(object)
    .reduce((memo, item) => [
      ...memo,
      ...item,
    ], []);
}

function getObjectPath(path: string | string[]): string[] {
  return typeof path === 'string'
    ? path.split('.')
    : path;
}

export function objectGet(obj: any, path: string | string[]): any {
  if (!obj) {
    return;
  }
  const arr = getObjectPath(path);
  return arr.reduce((memo, item): any => memo?.[item], obj);
}

export function objectSet(obj: any, path: string[], value: any, isDeepCopy = false) {
  if (!obj) {
    return;
  }

  const arr = getObjectPath(path);
  const res = isDeepCopy ? { ...obj } : obj;

  arr.reduce(((node, item, index) => {
    const isLast = arr.length - 1 === index;
    node[item] = isLast
      // set
      ? value
      // copy value or create new
      : (isDeepCopy ? { ...node[item] } : node[item]);
    return node[item];
  }), res);

  return res;
}
