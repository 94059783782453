import { Divider, Form, Input } from 'antd';
import { type FormListProps } from 'antd/lib/form/FormList';
import { createFormList, FormListItemProps } from 'features/Form';
import { ContactBlockTypes, ContactGroups } from '../../Contact.interface';
import { createContactList } from '../ContactItem/ContactItem';

function ContactGroupItem({ field }: FormListItemProps) {
  return (
    <div>
      <Form.Item name={[field.name, 'title']}>
        <Input placeholder="Заголовок группы" />
      </Form.Item>

      <Form.Item name={[field.name, 'sub_title']}>
        <Input placeholder="Подзаголовок группы" />
      </Form.Item>

      <Divider />

      <Form.List name={[field.name, 'contacts']}>
        {createContactList}
      </Form.List>
    </div>
  );
}

const createContactGroupList = createFormList<ContactGroups>(ContactGroupItem, {
  addLabel: 'Добавить группу',
  model: {
    title: '',
    sub_title: '',
    contacts: [
      {
        type: ContactBlockTypes.Address,
        text: '',
      },
    ],
  },
});

export function ContactGroupList({ ...props }: Omit<FormListProps, 'children'>) {
  return (
    <Form.List {...props}>
      {createContactGroupList}
    </Form.List>
  );
}
