import { DeleteOutlined } from '@ant-design/icons';
import { App, Button, Flex, Form as AntForm, FormInstance } from 'antd';
import { useLayoutConfirm } from 'features/Layout';
import { PropsWithChildren, ReactNode, useCallback, useMemo, useState } from 'react';
import { objectGet, objectSet } from 'utils/object';
import { promiseMap } from 'utils/promise';
import { useFormActionRemove } from '../../Form.hooks';
import { IFormItemConfig, onFormFinishFunc } from '../../Form.interface';
import { FormItem } from '../FormItem/FormItem';

export interface IFormFormProps<T> extends PropsWithChildren {
  onFinish: onFormFinishFunc<T>;
  initialValues?: Partial<T>;
  actions?: ReactNode[] | ReactNode;
  saveLabel?: string;
  onRemove?: Function | false;
  disabled?: boolean;
  form: FormInstance<T>;
  items?: IFormItemConfig[];
  before?: ReactNode;
  after?: ReactNode;
}

export function FormForm<T>({ initialValues, form, before, after, items, children, actions, saveLabel, onFinish, onRemove, disabled }: IFormFormProps<T>) {
  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const handleFormRemove = useFormActionRemove(onRemove);

  const handleFinish = useCallback(async (values: T) => {
    setIsLoading(true);
    try {
      // TODO @dkchv: initial values are ignored, v will be undefined if not rendrered?
      const result = { ...values };

      // serialize values
      if (items) {
        await promiseMap(items, async (item) => {
          const v = objectGet(values, item.formItemProps.name);
          const fixed = item.serializer
            ? await item.serializer(v)
            : v;
          objectSet(result, item.formItemProps.name, fixed, false);
          return null;
        });
      }

      await onFinish(result);
    } catch (e) {
      message.error(e.message);
      return;
    } finally {
      setIsLoading(false);
    }
  }, [items, message, onFinish]);

  const handleRemove = useCallback(async () => {
    setIsLoading(true);
    try {
      await handleFormRemove();
    } finally {
      setIsLoading(false);
    }
  }, [handleFormRemove]);

  const handleRemoveClick = useLayoutConfirm('Удалить?', handleRemove);

  const values = useMemo(() => {
    if (!items) {
      return initialValues;
    }

    // apply item's parser to value
    return items.reduce((memo, item) => {
      const v = objectGet(initialValues, item.formItemProps.name);
      const fixes = item.parser ? item.parser(v) : v;
      return objectSet(memo, item.formItemProps.name, fixes, true);
    }, {});
  }, [initialValues, items]);

  return (
    <AntForm
      className="w-3/4"
      layout="vertical"
      initialValues={values}
      form={form}
      onFinish={handleFinish}
      disabled={isLoading || disabled}
    >
      {before}

      {items?.map((item, index) => <FormItem key={index} model={item} />)}

      {children}

      {after}

      {/* actions */}
      <Flex gap="large" className="mt-6">
        {actions || (
          <Button type="primary" htmlType="submit">
            {saveLabel || 'Сохранить'}
          </Button>
        )}
        {onRemove && (
          <Button
            icon={<DeleteOutlined />}
            disabled={isLoading}
            onClick={handleRemoveClick}
            danger
          >
            Удалить
          </Button>
        )}
      </Flex>
    </AntForm>
  );
}
