export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function promiseRejectIn(ms: number) {
  return new Promise((_, reject) => {
    setTimeout(reject, ms);
  });
}

export function promiseWithReject<T>(promise: T, ms: number): T {
  return Promise.race([
    promise,
    promiseRejectIn(ms),
  ]) as T;
}

type PromiseMapIteratorFunc<T, V = T> = (item: T) => V | Promise<V>;

export function promiseMap<T, V>(value: T[], iterator: PromiseMapIteratorFunc<T, V>): Promise<V[]> {
  const promises = value.map(iterator);
  return Promise.all(promises);
}
