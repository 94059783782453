import { useParams } from 'react-router-dom';
import { StationServiceEdit } from '../../StationService/components/StationServiceEdit/StationServiceEdit';
import { StationServiceEditParams } from '../../StationService/StationService.intefrace';
import { stationEditUrl, StationTabTypes } from '../Station.model';

export function StationStationServiceEdit() {
  const { serviceType, serviceSlug, stationSlug, serviceStatus } = useParams<StationServiceEditParams>();
  return (
    <StationServiceEdit
      serviceSlug={serviceSlug}
      serviceType={serviceType}
      serviceStatus={serviceStatus}
      stationSlug={stationSlug}
      backUrl={stationEditUrl(stationSlug, StationTabTypes.ServiceTable, serviceType)}
    />
  );
}
