import { useAuthRoles } from 'features/Auth';
import { useMemo } from 'react';
import { useDirectorateListLoadQuery } from './Directorate.api';
import { DIRECTORATE_ROLE_PREFIX, getDirectorateRoleAdmin, isDirectorateAdmin, isDirectorateSuperAdmin } from './Directorate.model';

export function useDirectorateRoles() {
  return useAuthRoles(DIRECTORATE_ROLE_PREFIX);
}

export function useDirectorateListWithRoles() {
  const { data = [], ...rest } = useDirectorateListLoadQuery();
  const roles = useAuthRoles(DIRECTORATE_ROLE_PREFIX);

  const filtered = useMemo(() => {
    if (isDirectorateSuperAdmin(roles)) {
      return data;
    }
    return data.filter((item) => roles.includes(getDirectorateRoleAdmin(item.slug)))
  }, [data, roles]);

  return {
    data: filtered,
    ...rest,
  };
}

export function useDirectorateIsAdmin(slug?: string): boolean {
  const roles = useAuthRoles(DIRECTORATE_ROLE_PREFIX);
  return isDirectorateAdmin(roles, slug);
}
