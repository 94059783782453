import type { TDescendant } from '@udecode/plate-common';
import { BaseText } from 'slate';

// leaf

export interface SlateMark {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strikethrough: boolean;
}

export type ISlateText = BaseText & Partial<SlateMark>;

export enum SlateElementTypes {
  Paragraph = 'paragraph',
  P = 'p',
  Image = 'image',
  Img = 'img',
  Link = 'link',
  A = 'a',
  Quote = 'quote',
  Youtube = 'youtube',
}

// elements

export interface SlateElementBase<T extends SlateElementTypes> {
  type: T;
  children: ISlateDescendant[];
  id?: string;
}

export interface ISlateElementParagraph extends SlateElementBase<SlateElementTypes.Paragraph> {}

export interface ISlateElementP extends SlateElementBase<SlateElementTypes.P> {}

export interface ISlateElementQuote extends SlateElementBase<SlateElementTypes.Quote> {}

export interface ISlateElementYoutube extends SlateElementBase<SlateElementTypes.Youtube> {
  id: string;
}

export interface ISlateElementLink extends SlateElementBase<SlateElementTypes.Link> {
  url: string;
}

export interface ISlateElementA extends SlateElementBase<SlateElementTypes.A> {
  url: string;
}

export interface ISlateElementImage extends SlateElementBase<SlateElementTypes.Image> {
  url: string;
  name?: string;
}

export interface ISlateElementImg extends SlateElementBase<SlateElementTypes.Img> {
  url: string;
}

// all nodes
export type ISlateElement = ISlateElementParagraph
  | ISlateElementP
  | ISlateElementImage
  | ISlateElementImg
  | ISlateElementQuote
  | ISlateElementLink
  | ISlateElementA
  | ISlateElementYoutube
  ;

export type ISlateDescendant = TDescendant;
