import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { IFormItemConfig, useFormActionCommon, useFormActionRemove } from 'features/Form';
import { useLayoutConfirm } from 'features/Layout';
import { Spinner } from 'features/UI';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { ModerateStatusTypes } from '../../Moderate.interface';
import { ModerateReview } from '../ModerateReview/ModerateReview';

export function ModerateFormOnModeration<T>({ next, status, items, onApprove, onDecline, onRemoveDraft, onPrevLoad }: {
  next: T;
  status: ModerateStatusTypes;
  onApprove: MouseEventHandler<HTMLElement>;
  onDecline: MouseEventHandler<HTMLElement>;
  onRemoveDraft: MouseEventHandler<HTMLElement>;
  onPrevLoad: Function;
  items: IFormItemConfig[];
}) {
  const [prev, setPrev] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const handleOnApprove = useLayoutConfirm('Принять изменения?', useFormActionCommon(onApprove));
  const handleOnDecline = useLayoutConfirm('Отклонить изменения?', useFormActionCommon(onDecline));
  const handleOnRemoveDraft = useLayoutConfirm('Удалить черновик?', useFormActionRemove(onRemoveDraft));

  const handlePrevLoad = useCallback(async () => {
    try {
      if (status === ModerateStatusTypes.NewOnModeration) {
        return;
      }
      const { data } = await onPrevLoad();
      setPrev(data);
    } catch (e) {
      console.error('--error prev load', e);
    } finally {
      setIsLoading(false);
    }
  }, [onPrevLoad, status]);

  useEffect(() => {
    handlePrevLoad();
  }, [handlePrevLoad]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <ModerateReview
        prev={prev}
        next={next}
        items={items}
      />

      {/* actions */}
      <Space className="mt-9">
        <Button
          type="primary"
          onClick={handleOnApprove}
          icon={<CheckOutlined />}
        >
          Опубликовать
        </Button>
        <Button
          type="primary"
          onClick={handleOnDecline}
          icon={<CloseOutlined />}
          danger
        >
          Отклонить
        </Button>
        <Button
          onClick={handleOnRemoveDraft}
          icon={<DeleteOutlined />}
          danger
        >
          Удалить черновик
        </Button>
      </Space>
    </div>
  );
}
