import { Flex } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormItemTypes } from 'features/Form';
import { moderateReviewRenderCheckbox, moderateReviewRenderImage, moderateReviewRenderLink, moderateReviewRenderRichText, moderateReviewRenderText } from './components/ModerateReview/ModerateReview';
import { ModerateStatus } from './components/ModerateStatus/ModerateStatus';
import { IModerateable, IModerateReviewRenderFunc, ModerateStateTypes, ModerateStatusTypes } from './Moderate.interface';

export function isModerateStateDraftOrNew(state: ModerateStateTypes): boolean {
  return state === ModerateStateTypes.Draft || state === ModerateStateTypes.New;
}

export function isModerateStatusOnModeration(status: ModerateStatusTypes): boolean {
  return status === ModerateStatusTypes.NewOnModeration || status === ModerateStatusTypes.DraftOnModeration;
}

export function isModerateStatusPublished(status: ModerateStatusTypes): boolean {
  return status === ModerateStatusTypes.Published;
}

export function createModerateColumns<T extends IModerateable>(columnList: ColumnsType<T>): ColumnsType<T> {
  return [
    ...columnList,
    {
      title: 'Статус',
      dataIndex: 'status',
      align: 'right',
      render: (status) => (
        <Flex gap={8} justify="end">
          <ModerateStatus status={status} />
        </Flex>
      ),
    },
    // {
    //   align: 'end',
    //   width: 64,
    //   render: (_, item) => <ModerateActionDropdown model={item} actions={actions} />,
    // },
  ];
}

export function getModerateState(status: ModerateStatusTypes): ModerateStateTypes {
  switch (status) {
    case ModerateStatusTypes.New:
      return ModerateStateTypes.New;
    case ModerateStatusTypes.Draft:
      return ModerateStateTypes.Draft;
    case ModerateStatusTypes.Published:
      return ModerateStateTypes.Published;
    case ModerateStatusTypes.NewOnModeration:
    case ModerateStatusTypes.DraftOnModeration:
      return ModerateStateTypes.Draft;
    default:
      return ModerateStateTypes.New;
  }
}

export const moderateFormItemComponentMap: Record<FormItemTypes, IModerateReviewRenderFunc> = {
  [FormItemTypes.Text]: moderateReviewRenderText,
  [FormItemTypes.CheckBox]: moderateReviewRenderCheckbox,
  [FormItemTypes.Link]: moderateReviewRenderLink,
  [FormItemTypes.Image]: moderateReviewRenderImage,
  [FormItemTypes.RichText]: moderateReviewRenderRichText,
  [FormItemTypes.CatalogSelector]: moderateReviewRenderText,
}
