import { Form, Input } from 'antd';
import { featureFlags } from 'App.environment';
import { FormFileUpload, formRuleRequired } from 'features/Form';

export function PagePageFormInternal() {
  return (
    <div>
      <Form.Item
        label="Заголовок"
        name="title"
        validateTrigger="onBlur"
        rules={[formRuleRequired('Введите заголовок')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        className="mb-4"
        label="Баннер"
        name="banner"
      >
        <FormFileUpload />
      </Form.Item>

      {featureFlags.isDev && (
        <Form.Item
          label="Cсылка"
          name="link"
          rules={[formRuleRequired('Введите ссылку')]}
        >
          <Input />
        </Form.Item>
      )}
    </div>
  );
}
