import type { MenuProps } from 'antd';
import type { KeycloakRoles } from 'keycloak-js';
import { canViewDirectorate, isDirectorateSuperAdmin } from './features/Directorate/Directorate.model';
import { canViewNews } from './features/News/News.model';
import { canViewPages } from './features/Page/Page.model';
import { canViewServices } from './features/Service/Service.model';
import { canViewStationTable } from './features/Station/Station.model';
import { canViewUsers } from './features/User/Users.model';

type IMenuItem = Required<MenuProps>['items'][number] & {
  canView?: (roles: KeycloakRoles['roles']) => boolean;
};

export const appMenu: IMenuItem[] = [
  {
    key: 'news',
    label: 'Новости',
    canView: canViewNews,
  },
  {
    key: 'stations',
    label: 'Вокзалы',
    canView: canViewStationTable,
  },
  {
    key: 'timetable',
    label: 'Табло',
    canView: canViewStationTable,
  },
  {
    key: 'services',
    label: 'Услуги',
    canView: canViewServices,
  },
  {
    key: `administration`,
    label: 'Руководство',
    canView: isDirectorateSuperAdmin,
  },
  {
    key: `contacts`,
    label: 'Контакты',
    canView: isDirectorateSuperAdmin,
  },
  {
    key: 'directorate',
    label: 'Подразделения',
    canView: canViewDirectorate,
  },
  {
    key: 'pages',
    label: 'Контент',
    canView: canViewPages,
  },
  {
    key: 'users',
    label: 'Пользователи',
    canView: canViewUsers,
  },
];
