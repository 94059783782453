import type { FormInstance, UploadFile } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { IFormSelectOption } from 'features/Form';
import { LanguageTypes } from 'features/Language';
import { UseParamsBase } from 'features/Router';
import { FC } from 'react';

export enum PageViewTypes {
  Page = 'page',
  Block = 'block',
}

export enum PagePageTemplateTypes {
  Internal = 'internal',
  Basic = 'basic',
}

export enum PageBlockTemplateTypes {
  Image = 'image',
  Text = 'text',
  Phone = 'phone',
  Button = 'button',
  Carousel = 'carousel',
  Menu = 'menu',
}

export type IPageTemplate = PagePageTemplateTypes | PageBlockTemplateTypes;

export interface IPageCreateFormParams extends UseParamsBase {
  type: IPage['view_type'];
}

export interface PagePageEditParams extends IPageCreateFormParams {
  slug: IPage['slug'];
}

export interface PageFormProps {
  model?: IPage;
  onFinish: (base: IPageFormBase, info: IPageFormInfo) => any;
  templateOptions: IFormSelectOption[];
  onRemove?: () => any;
}

export interface IPageConfig {
  createLabel: string;
  createUrl: string;
  createSuccess: string;
  tableColumnList: ColumnsType<IPage>;
  updateSuccess: string;
  removeSuccess: string;
  editLabel: string;
  Form: FC<PageFormProps>;
  formTemplateOptions: IFormSelectOption[];
}

export interface IPage {
  slug: string;
  parent: string;
  view_type: PageViewTypes;
  template: IPageTemplate;
  link: string;
  info: IPageInfo;
}

export interface IPageInfo {
  lang: LanguageTypes;
  title: string;
  content: string;
  attachments: string[];
}

// form

export interface IPagePageFormBase {
  title: IPageInfo['title'];
  banner: UploadFile[];
  link: IPage['link'];
  template: IPage['template'];
  slug: IPage['slug'];
}

export interface IPagePageForm extends IPagePageFormBase {
  content: IPageInfo['content'];
  parent: IPage['parent'];
}

export interface IPageBlockForm extends IPagePageFormBase {
  // from templates
  content: IPageInfo['content'] | IPageBlockCarouselItem[];
}

export interface IPageFormBase {
  attachments: IPageInfo['attachments'];
  slug: IPage['slug'];
  template: IPage['template'];
  link: IPage['link'];
  parent: IPage['parent'];
}

export interface IPageFormInfo {
  title: IPageInfo['title'];
  content: IPageInfo['content'];
}

// api

export type IPageMutationRequest = Pick<IPage, 'slug' | 'view_type'>;
export type IPageUpdateRequest = Pick<IPage, 'slug' | 'template' | 'link' | 'parent'>;

export interface IPageCreateRequest {
  slug: IPage['slug'];
  attachments: IPageInfo['attachments'];
  view_type: IPage['view_type'];
}

export interface IPageInfoUpdateRequest extends IPageInfo {
  slug: IPage['slug'];
  view_type: IPage['view_type'];
}

// -- page form

export interface IPagePageTemplateConfig {
  label: string;
  Form: FC<void>;
}


// -- block form

export interface IPageBlockEditFormProps {
  model: IPage;
  form: FormInstance;
}

export interface IPageBlockTemplateConfig {
  label: string;
  Form: FC<IPageBlockEditFormProps>;
  contentParser?: (value: string) => any;
  contentSerializer?: (value: any) => string | Promise<string>;
}

// carousel

interface IPageBlockCarouselItemBase {
  title: string;
  description: string;
  link: string;
}

export interface IPageBlockCarouselItem extends IPageBlockCarouselItemBase {
  banner: UploadFile[];
}

export interface IPageBlockCarouselItemUploaded extends IPageBlockCarouselItemBase {
  banner: string;
}

// menu block

export enum MenuItemTypes {
  Link = 'link',
  Group = 'group',
}

interface IMenuItemBase {
  type: MenuItemTypes;
  label: string;
}

export interface IMenuItemLink extends IMenuItemBase {
  url: string;
  slug: string;
  img: string;
}

export interface IMenuItemGroup extends IMenuItemBase {
  children: IMenuItem[];
}

export type IMenuItem = IMenuItemLink | IMenuItemGroup;
