import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Upload, UploadFile, UploadProps } from 'antd';
import { isLocal } from 'App.environment';
import classnames from 'classnames';
import { isMediaMgn } from 'features/Media';
import { useCallback, useState } from 'react';
import { fileImageToBase64 } from 'utils/file';

export function FormFileUpload({ value = [], onChange, maxCount = 1, ...props }: {
  value?: UploadFile[];
  onChange?: (files: UploadFile[]) => any;
} & UploadProps) {
  const [fileList, setFileList] = useState<UploadFile[]>(value);

  const handleChange: UploadProps['onChange'] = useCallback(async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    onChange?.(newFileList);
  }, [onChange]);

  return (
    <Upload
      action={fileImageToBase64}
      onChange={handleChange}
      className="upload-list-custom"
      defaultFileList={fileList}
      listType="picture"
      previewFile={fileImageToBase64}
      accept="image/*"
      itemRender={(node, file, fileList, actions) => (
        <RenderItem file={file} onRemove={actions.remove} />
      )}
      maxCount={maxCount}
      {...props}
    >
      {fileList.length < maxCount && (
        <Button icon={<PlusOutlined />}>
          Загрузить
        </Button>
      )}
    </Upload>
  );
}

function RenderItem({ file, onRemove }: {
  file: UploadFile;
  onRemove: any;
}) {
  return (
    <Card size="small" className="mt-2">
      <Flex gap={16} align="center">
        <img
          className={classnames('overflow-hidden', {
          'debug10': isLocal && isMediaMgn(file.url),
        })}
          src={file.url || file.thumbUrl}
          alt=""
        />
        <Button
          className="flex-shrink-0"
          onClick={onRemove}
          icon={<DeleteOutlined />}
          type="text"
        />
      </Flex>
    </Card>
  );
}
