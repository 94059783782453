import { FormInstance, FormItemProps, FormListFieldData, FormListOperation, SelectProps } from 'antd';
import { FC, ReactNode } from 'react';
import { ArrayElement } from 'utils/array';

export type IFormSelectOption = ArrayElement<SelectProps['options']>;

export interface FormListItemProps<T = any> {
  field: FormListFieldData;
  form: FormInstance;
  meta: T;
}

export interface FormListMetaBase {
  onAdd: FormListOperation['add'];
  onRemove: FormListOperation['remove'];
}

export interface FormListOptions<T> {
  addLabel: string;
  addButton: ReactNode;
  isAdd: boolean;
  mode: FormListItemModeTypes;
  model: T;
  form: FormInstance;
  meta: FormListMetaBase & any;
}

export enum FormListItemModeTypes {
  Normal= 'normal',
  Inline = 'inline',
  Compact = 'compact',
}

export type onFormFinishFunc<T> = (values: T) => any;

// item

export enum FormItemTypes {
  Text = 'text',
  CheckBox = 'checkbox',
  Link = 'link',
  Image = 'image',
  RichText = 'richText',
  CatalogSelector = 'catalogSelector',
}

export interface IFormItemConfig<M = any, F = any> {
  type: FormItemTypes;
  component?: FC<FormItemProps>;
  parser?: (value: M) => F;
  serializer?: (value: F) => M;
  formItemProps?: FormItemProps;
  inputProps?: any;
}
