import type { ColumnsType } from 'antd/lib/table';
import type { KeycloakRoles } from 'keycloak-js';
import { Link } from 'react-router-dom';
import { createURL } from 'utils/url';
import { ROLE_ADMIN_SUFFIX } from '../Role/Role.model';
import { DirectorateFormTabTypes, IDirectorate, IDirectorateManager, IDirectorateManagerForm } from './Directorate.interface';

export const DIRECTORATE_SLUG_HEAD = 'head';
const DIRECTORATE_ADMINISTRATION_URL = '/administration';

function getDirectorateUrl(slug: string, url: string) {
  return slug === DIRECTORATE_SLUG_HEAD
    ? DIRECTORATE_ADMINISTRATION_URL
    : url;
}

const directorateManagerEditFormUrl = (slug: string, id: number) => createURL(`/directorate/${slug}/manager/${id}`);
export const directorateManagerCreateFormUrl = (slug: string) => createURL(`/directorate/${slug}/manager/create`);

export const directorateFormBackUrl = (slug: string) => getDirectorateUrl(slug, '/directorate');
export const directorateManagerTableUrl = (slug: string) => getDirectorateUrl(slug, createURL(`/directorate/${slug}`, {
  tab: DirectorateFormTabTypes.Manager,
}));

export const directorateColumnList: ColumnsType<IDirectorate> = [
  {
    title: 'Название дирекции',
    dataIndex: ['info', 'name'],
    render: (title, item) => (
      <Link to={`/directorate/${item.slug}`}>{title || 'Нет названия'}</Link>
    ),
  },
];

export const directorateManagerColumnList = (slug: string): ColumnsType<IDirectorateManager> => [
  {
    title: 'ФИО',
    render: (value, item) => (
      <Link to={directorateManagerEditFormUrl(slug, item.id)}>
        {`${item.info.last_name} ${item.info.first_name} ${item.info.patronymic}`}
      </Link>
    ),
  },
  {
    title: 'Должность',
    dataIndex: ['info', 'position'],
  },
];

export const directorateManagerEmpty: IDirectorateManagerForm = {
  firstName: '',
  lastName: '',
  patronymic: '',
  position: '',
  image: [],
};

export const DIRECTORATE_ROLE_PREFIX = 'roles_directorates';


export function getDirectorateRoleAdmin(slug: string) {
  return `${DIRECTORATE_ROLE_PREFIX}_${slug}_${ROLE_ADMIN_SUFFIX}`;
}

export const DIRECTORATE_HEAD_ROLE_ADMIN = getDirectorateRoleAdmin(DIRECTORATE_SLUG_HEAD);

export function canViewDirectorate(roles: KeycloakRoles['roles']) {
  return roles.some((item) => item.startsWith(DIRECTORATE_ROLE_PREFIX));
}

export function isDirectorateSuperAdmin(roles: KeycloakRoles['roles']): boolean {
  return roles.includes(DIRECTORATE_HEAD_ROLE_ADMIN);
}

export function isDirectorateAdmin(roles: KeycloakRoles['roles'], slug?: string) {
  return isDirectorateSuperAdmin(roles) || roles.includes(getDirectorateRoleAdmin(slug));
}
