import { Checkbox, FormItemProps, FormRule, Input } from 'antd';
import { PlateEditor } from 'features/RictText2';
import { FC } from 'react';
import { stringAZRegExp } from 'utils/string';
import { prefixUrl, urlRegExp } from 'utils/url';
import { FormFileUpload } from './components/FormFileUpload/FormFileUpload';
import { FormItemTypes, IFormItemConfig, IFormSelectOption } from './Form.interface';

export function formRuleRequired(message: string = 'Обязательное поле'): FormRule {
  return {
    required: true,
    message,
  };
}

export function formRuleUrl(message: string = 'Введите корректную ссылку'): FormRule {
  return {
    pattern: urlRegExp,
    message,
  };
}

export function formRuleAZ(message: string): FormRule {
  return {
    pattern: stringAZRegExp,
    message,
  };
}

export function formRuleEmail(message: string): FormRule {
  return {
    required: true,
    type: 'email',
    message,
  };
}

export function formFilter(input: string = '', field: string = ''): boolean {
  return field.toLowerCase().includes(input.toLowerCase());
}

export function formFilterOptionByLabel(input: string, option?: IFormSelectOption): boolean {
  return formFilter(input, String(option?.label || ''));
}

// errors

export class FormCommonError extends Error {
  constructor() {
    super('Ошибка при отправке формы');
  }
}

export class FormCommonRemoveError extends Error {
  constructor() {
    super('Ошибка при удалении');
  }
}

export const formItemTypeComponentMap: Record<FormItemTypes, FC> = {
  [FormItemTypes.Text]: Input,
  [FormItemTypes.CheckBox]: Checkbox,
  [FormItemTypes.Link]: Input,
  [FormItemTypes.Image]: FormFileUpload,
  [FormItemTypes.RichText]: PlateEditor,
  [FormItemTypes.CatalogSelector]: null,
};

export function createFormTextField(props: FormItemProps): IFormItemConfig {
  return {
    type: FormItemTypes.Text,
    formItemProps: {
      validateTrigger: 'onBlur',
      rules: [formRuleRequired()],
      ...props,
    },
  };
}

export function createFormCheckboxField(name: string, label: string): IFormItemConfig {
  return {
    type: FormItemTypes.CheckBox,
    serializer: Boolean,
    formItemProps: {
      name,
      valuePropName: 'checked',
    },
    inputProps: {
      children: label,
    },
  };
}

export function createFormLinkField(name: string, label: string): IFormItemConfig {
  return {
    type: FormItemTypes.Link,
    serializer: prefixUrl,
    formItemProps: {
      name,
      label,
      className: 'w-2/3',
      rules: [formRuleUrl()],
    },
  };
}

export function getFormCheckboxValue(value: boolean) {
  return !!value ? 'Да' : 'Нет';
}
