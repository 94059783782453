import { RouteProps } from 'react-router-dom';
import { simpleRouter } from '../Simple/Simple.router';
import { stationServiceRouter } from '../StationService/StationService.router';
import { ServiceComplexCreate } from './components/ServiceComplexCreate/ServiceComplexCreate';
import { ServiceComplexEdit } from './components/ServiceComplexEdit/ServiceComplexEdit';
import { ServiceStationServiceCreate } from './components/ServiceStationServiceCreate/ServiceStationServiceCreate';
import { ServiceStationServiceEdit } from './components/ServiceStationServiceEdit/ServiceStationServiceEdit';
import { ServiceTable } from './components/ServiceTable/ServiceTable';

export const serviceRouter: RouteProps[] = [
  // overrides
  ...simpleRouter,
  ...stationServiceRouter,
  // service CRUD
  {
    path: '/services',
    Component: ServiceTable,
  },
  {
    path: '/services/:serviceType/create',
    Component: ServiceComplexCreate,
  },
  {
    path: '/services/:serviceType/:serviceSlug/:status',
    Component: ServiceComplexEdit,
  },
  // service station CRUD
  {
    path: '/services/:serviceType/:serviceSlug/:serviceStatus/station/create',
    Component: ServiceStationServiceCreate,
  },
  {
    path: '/services/:serviceType/:serviceSlug/:serviceStatus/station/:stationSlug',
    Component: ServiceStationServiceEdit,
  },
];
