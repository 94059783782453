import { useAuthRoles } from 'features/Auth';
import { isModerateStateDraftOrNew, ModerateStateTypes } from 'features/Moderate';
import { ServiceType } from './Service.interface';
import { getServiceRoleAdmin, SERVICE_ROLE_PREFIX, serviceCRUDMap } from './Service.model';

export function useServiceListLoad(type: ServiceType, state: ModerateStateTypes = ModerateStateTypes.Published) {
  const { useListLoad } = serviceCRUDMap[type];
  return useListLoad({ state });
}

export function useServiceItemLoad(type: ServiceType, slug: string, state?: ModerateStateTypes) {
  const { useItemLoad } = serviceCRUDMap[type];
  return useItemLoad({
    slug,
    is_draft: isModerateStateDraftOrNew(state),
  });
}

export function useServiceItemCreate(type: ServiceType) {
  const { useItemCreate } = serviceCRUDMap[type];
  return useItemCreate();
}

export function useServiceItemRemove(type: ServiceType) {
  const { useItemRemove } = serviceCRUDMap[type];
  return useItemRemove();
}

export function useServiceItemUpdate(type: ServiceType) {
  const { useItemUpdate } = serviceCRUDMap[type];
  return useItemUpdate();
}

export function useServiceItemInfoUpdate(type: ServiceType) {
  const { useItemInfoUpdate } = serviceCRUDMap[type];
  return useItemInfoUpdate();
}

// roles

export function useServiceIsAdmin(type: ServiceType) {
  const roles = useAuthRoles(SERVICE_ROLE_PREFIX);
  return roles.includes(getServiceRoleAdmin(type));
}

// moderation

export function useServiceItemStartModeration(type: ServiceType) {
  return serviceCRUDMap[type].useItemStartModeration();
}

export function useServiceItemApprove(type: ServiceType) {
  return serviceCRUDMap[type].useItemApprove();
}

export function useServiceItemDecline(type: ServiceType) {
  return serviceCRUDMap[type].useItemDecline();
}

export function useServicePrevLoad(type: ServiceType) {
  return serviceCRUDMap[type].useItemPrevLoad();
}

export function useServiceRemoveDraft(type: ServiceType) {
  return serviceCRUDMap[type].useItemRemoveDraft();
}
