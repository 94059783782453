import { App } from 'antd';
import { LANGUAGE_DEFAULT } from 'features/Language';
import { LayoutContent } from 'features/Layout';
import { ModerateStatusTypes } from 'features/Moderate';
import { ServiceType } from 'features/Service';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { contactSerialize } from '../../../Contact/Contact.model';
import { useServiceItemLoad } from '../../../Service/Service.hook';
import { useStationLoadQuery } from '../../../Station/Station.api';
import { useStationServiceInfoUpdateMutation, useStationServiceRemoveMutation, useStationServiceUpdateMutation } from '../../StationService.api';
import { useStationServiceLoad, useStationServiceSimpleListRemove } from '../../StationService.hooks';
import { IStationServiceForm } from '../../StationService.intefrace';
import { StationServiceForm } from '../StationServiceForm/StationServiceForm';

export function StationServiceEdit({ serviceType, serviceStatus, serviceSlug, stationSlug, backUrl }: {
  serviceType: ServiceType;
  serviceStatus: ModerateStatusTypes;
  serviceSlug: string;
  stationSlug: string;
  backUrl: string;
}) {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { data: service } = useServiceItemLoad(serviceType, serviceSlug);
  const { data: station } = useStationLoadQuery(stationSlug);

  const params = useMemo(() => !!service && ({
    station_slug: stationSlug,
    service_type: serviceType,
    service_id: service.id,
  }), [service, serviceType, stationSlug]);
  const [stationService] = useStationServiceLoad(params);

  const [stationServiceRemove] = useStationServiceRemoveMutation();
  const [stationServiceUpdate] = useStationServiceUpdateMutation();
  const [stationServiceInfoUpdate] = useStationServiceInfoUpdateMutation();
  const stationServiceSimpleListRemove = useStationServiceSimpleListRemove();

  const handleFinish = useCallback(async (values: IStationServiceForm, attachments: string[]) => {
    await Promise.all([
      stationServiceUpdate({
        stationServiceId: stationService.id,
        external_link: values.externalLink,
        attachments,
      }).unwrap(),
      stationServiceInfoUpdate({
        serviceId: stationService.id,
        lang: LANGUAGE_DEFAULT,
        content: values.content,
        title: values.title,
        contacts: contactSerialize(values.contacts),
      }).unwrap(),
    ]);
    message.success('Услуга на вокзале обновлена');
    navigate(backUrl);
  }, [backUrl, message, navigate, stationService, stationServiceInfoUpdate, stationServiceUpdate]);

  const handleRemove = useCallback(async (id: number) => {
    await stationServiceSimpleListRemove(stationService);
    await stationServiceRemove(id).unwrap();
    message.success('Услуга на вокзале удалена');
    navigate(backUrl);
  }, [backUrl, message, navigate, stationService, stationServiceRemove, stationServiceSimpleListRemove]);

  return (
    <LayoutContent
      title="Редактирование услуги на вокзале"
      backUrl={backUrl}
      isLoading={!stationService}
      render={() => (
        <StationServiceForm
          model={stationService}
          station={station}
          serviceType={serviceType}
          serviceStatus={serviceStatus}
          service={service}
          onFinish={handleFinish}
          onRemove={handleRemove}
          isSimpleTable={serviceType === ServiceType.Flagship}
        />
      )}
    >
    </LayoutContent>
  );
}
