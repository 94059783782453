const stringTransliterateMap = {
  'а': 'a',
  'б': 'b',
  'в': 'v',
  'г': 'g',
  'д': 'd',
  'е': 'e',
  'ё': 'yo',
  'ж': 'zh',
  'з': 'z',
  'и': 'i',
  'й': 'i',
  'к': 'k',
  'л': 'l',
  'м': 'm',
  'н': 'n',
  'о': 'o',
  'п': 'p',
  'р': 'r',
  'с': 's',
  'т': 't',
  'у': 'u',
  'ф': 'f',
  'х': 'h',
  'ц': 'ts',
  'ч': 'ch',
  'ш': 'sh',
  'щ': 'sch',
  'ы': 'i',
  'э': 'e',
  'ю': 'yu',
  'я': 'ya',
  ' ': '-',
  '_': '',
  ',': '',
  '.': ''
};

export const stringAZRegExp = /^[-a-zA-Z]+$/;

export function stringTransliterate(word: string): string {
  return word
    .toLowerCase()
    .replace('ий', 'y')
    .replace('ый', 'y')
    .split('')
    .map((char) => stringTransliterateMap[char] || char)
    .join('');
}

export function createSlug(title: string): string {
  return stringTransliterate(String(title).toLowerCase().trim());
}

export function stringCompare(a: string, b: string) {
  return String(a).toLowerCase().localeCompare(String(b).toLowerCase());
}
