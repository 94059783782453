import { UploadFile } from 'antd';
import { FormItemTypes, IFormItemConfig } from 'features/Form';
import { getFileSalt } from 'utils/file';
import { promiseMap, promiseWithReject } from 'utils/promise';
import { clientMediaUpload } from './Media.client';
import { IMediaAttachment } from './Media.interface';

export const PUBLIC_MEDIA_HOST = 'https://mgn.dzvr.ru';

export function isMediaMgn(str = ''): boolean {
  return String(str).startsWith(PUBLIC_MEDIA_HOST);
}

export function getMediaUrl(src: string = ''): string {
  // workaround for urls without origin
  return String(src).startsWith('/media')
    ? `${PUBLIC_MEDIA_HOST}${src}`
    : src;
}

function createMediaAttach(link: string = '', isAlt: boolean): IMediaAttachment {
  return isAlt
    ? {
      type: 'img',
      link,
      alt: '',
    }
    : {
      type: 'img',
      link,
    };
}

export function createUploadFile(url: string, index?: number | string): UploadFile {
  return {
    uid: String(index || url),
    name: url,
    url: getMediaUrl(url),
    status: 'done',
  };
}

export function mediaAttachmentToUploadFile(list: IMediaAttachment[]): UploadFile[] {
  return list.map((item, index) => createUploadFile(item.link, index));
}

export function mediaSingleToUploadFile(link?: string): UploadFile[] {
  if (!link) {
    return [];
  }
  return [createUploadFile(link)];
}

export function mediaArrayToUploadFile(list: string[]): UploadFile[] {
  return (list || []).map((item, index) => createUploadFile(item, index));
}

async function mediaUploadFile(prefix: string, file: File, isAlt: boolean): Promise<IMediaAttachment> {
  const link = await clientMediaUpload(prefix, file);
  return createMediaAttach(link, isAlt);
}

export class MediaUploadError extends Error {
  constructor() {
    super('Ошибка при загрузке изображений');
  }
}

function getUrlFilename(url: string): string {
  const part = String(url).split('/');
  return part[part.length - 1];
}

export async function urlToFile(url: string): Promise<File> {
  const response = await fetch(url);
  const blob = await response.blob();
  const filename = getUrlFilename(url) || getFileSalt();
  return new File([blob], filename);
}

// re-upload mgn-file to our server
async function mediaUploadMgn(prefix: string, url: string, isAlt: boolean): Promise<IMediaAttachment> {
  try {
    const file = await urlToFile(url);
    return await promiseWithReject(mediaUploadFile(prefix, file, isAlt), 2000);
  } catch (e) {
    // fallback to url
    console.error('error upload file', e);
    return createMediaAttach(url, isAlt);
  }
}

export async function mediaUploadFileToString(prefix: string, file: UploadFile): Promise<string> {
  if (!file) {
    return '';
  }
  return file.url || await clientMediaUpload(prefix, file.originFileObj);
}

// TODO @dkchv: return string[] after fix on BE
export function mediaUploadFiles(prefix: string, files: UploadFile[], isAlt = false): Promise<IMediaAttachment[]> {
  if (!files) {
    return Promise.resolve([]);
  }
  try {
    return promiseMap(files, async (item): Promise<IMediaAttachment> => {
      if (!item.url) {
        return mediaUploadFile(prefix, item.originFileObj, isAlt);
      }

      if (!isMediaMgn(item.url)) {
        return Promise.resolve(createMediaAttach(item.url, isAlt));
      }

      return mediaUploadMgn(prefix, item.url, isAlt);
    });
  } catch (e) {
    throw new MediaUploadError();
  }
}

export async function mediaUploadFilesToStringArray(prefix: string, files: UploadFile[]): Promise<string[]> {
  const result = await mediaUploadFiles(prefix, files);
  return result.map((item) => item.link);
}

export function createMediaIconFormField(prefix: string): IFormItemConfig {
  return {
    type: FormItemTypes.Image,
    parser: mediaSingleToUploadFile,
    serializer: (value: UploadFile[]) => mediaUploadFileToString(prefix, value?.[0]),
    formItemProps: {
      name: 'icon',
      label: 'Иконка (svg)',
      className: 'mb-4',
    },
    inputProps: {
      accept: 'image/svg+xml',
    },
  };
}

export function createMediaAttachFormField(prefix: string): IFormItemConfig {
  return {
    type: FormItemTypes.Image,
    parser: mediaArrayToUploadFile,
    serializer: (value: UploadFile[]) => mediaUploadFilesToStringArray(prefix, value),
    formItemProps: {
      name: 'attachments',
      label: 'Баннер',
      className: 'mb-4',
    },
    inputProps: {
      maxCount: 10,
      multiple: true,
    },
  };
}
