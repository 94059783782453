import { TabsProps } from 'antd';
import { ModerateStatusTypes } from 'features/Moderate';
import { IServiceBase, ServiceType } from 'features/Service';
import { UITabs } from 'features/UI';
import { useMemo } from 'react';
import { IStation } from '../../../Station/Station.interface';
import { IStationService, IStationServiceForm } from '../../StationService.intefrace';
import { StationServiceTabTypes } from '../../StationService.model';
import { StationServiceBasicForm } from '../StationServiceBasicForm/StationServiceBasicForm';
import { StationServiceSimpleTable } from '../StationServiceSimpleTable/StationServiceSimpleTable';

export function StationServiceForm({ model, station, service, serviceType, serviceStatus, isSimpleTable, onRemove, onFinish }: {
  model?: IStationService;
  station?: IStation;
  service?: IServiceBase;
  serviceType: ServiceType;
  serviceStatus: ModerateStatusTypes;
  onFinish: (values: IStationServiceForm, attachments: string[]) => any;
  onRemove?: (id: number) => any;
  isSimpleTable?: boolean;
}) {
  const basicForm = useMemo(() => (
    <StationServiceBasicForm
      model={model}
      station={station}
      service={service}
      serviceType={serviceType}
      serviceStatus={serviceStatus}
      onRemove={onRemove}
      onFinish={onFinish}
    />
  ), [model, onFinish, onRemove, service, serviceType, station]);

  const tabs = useMemo((): TabsProps['items'] => {
    const result = [
      {
        key: StationServiceTabTypes.Basic,
        label: 'Основные',
        children: basicForm,
      },
    ];
    if (serviceType === ServiceType.Flagship) {
      result.push({
        key: StationServiceTabTypes.SimpleTable,
        label: 'Услуги',
        children: (
          <StationServiceSimpleTable
            stationSlug={station.slug}
            serviceSlug={service.slug}
            serviceType={serviceType}
            stationServiceId={model.id}
          />
        ),
      });
    }
    return result;
  }, [basicForm, model, service, serviceType, station]);

  return !isSimpleTable
    ? basicForm
    : <UITabs defaultActiveKey={StationServiceTabTypes.Basic} items={tabs} />;
}
