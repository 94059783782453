import { Tag } from 'antd';
import { Fragment } from 'react';
import { IModerateTag, ModerateStatusTypes } from '../../Moderate.interface';

const tagNew: IModerateTag = {
  label: 'Новый',
  color: 'green-inverse',
};

const tagDraft: IModerateTag = {
  label: 'Черновик',
  color: 'orange',
};

const tagOnModeration: IModerateTag = {
  label: 'На модерации',
  color: 'red',
};

const tagPublished: IModerateTag = {
  label: 'Опубликован',
  color: 'blue',
};

const moderateTagMap: Record<ModerateStatusTypes, IModerateTag[]> = {
  [ModerateStatusTypes.Published]: [tagPublished],
  [ModerateStatusTypes.Draft]: [tagDraft],
  [ModerateStatusTypes.New]: [tagNew],
  [ModerateStatusTypes.NewOnModeration]: [tagNew, tagOnModeration],
  [ModerateStatusTypes.DraftOnModeration]: [tagDraft, tagOnModeration],
};

export function ModerateStatus({ status }: {
  status?: ModerateStatusTypes;
}) {
  const list = moderateTagMap[status] || moderateTagMap[ModerateStatusTypes.New];

  return (
    <Fragment>
      {list.map((item, index) => (
        <Tag
          className="m-0"
          key={index}
          color={item.color}
        >{item.label}</Tag>
      ))}
    </Fragment>
  );
}
