import { AuthClientTokens } from '@react-keycloak/core/lib/types';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Spinner } from 'features/UI';
import React, { PropsWithChildren, useCallback } from 'react';
import { ClientFetchService } from '../Client';
import { authClient } from './Auth.client';

export function AuthProvider({ clientList, children }: PropsWithChildren<{
  clientList: ClientFetchService[];
}>) {
  // TODO @dkchv: deprecated, use authClient
  const handleUpdateTokens = useCallback((tokens: AuthClientTokens) => {
    clientList.forEach((item) => {
      item.setBearerToken(tokens.token);
    });
  }, [clientList]);

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      LoadingComponent={<Spinner />}
      onTokens={handleUpdateTokens}
      autoRefreshToken
    >
      {children}
    </ReactKeycloakProvider>
  );
}
