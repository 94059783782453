import { TagProps } from 'antd';
import { IFormItemConfig } from 'features/Form';
import { UseParamsBase } from 'features/Router';
import { FC, MouseEventHandler, ReactNode } from 'react';

export enum ModerateStatusTypes {
  New = 'new',
  Draft = 'draft',
  Published = 'published',
  DraftOnModeration = 'draft_on_moderation',
  NewOnModeration = 'new_on_moderation',
}

export enum ModerateStateTypes {
  Draft = 'draft',
  Published = 'published',
  PublishedOnly = 'published_only',
  New = 'new',
}

export interface IModerateable {
  status: ModerateStatusTypes;
}

export interface IModerateTag {
  label: string;
  color: TagProps['color'];
}

// for form

export interface UseModerateParams extends UseParamsBase {
  status: ModerateStatusTypes;
}

export interface IModerateFormComponentProps<T> {
  model?: T;
  onFinish: IModerateOnFinishFunc<T>;
  onRemove?: MouseEventHandler<HTMLElement>;
  onRemoveDraft?: MouseEventHandler<HTMLElement>;
  items: IFormItemConfig[];
  status?: IModerateable['status'];
}

export interface IModerateFormModerateProps<T> {
  model?: T;
  status: ModerateStatusTypes;
  onRemove: MouseEventHandler<HTMLElement>;
  onFinish: IModerateOnFinishFunc<T>;
  items: IFormItemConfig[];
  component: FC<IModerateFormComponentProps<T>>;
  // moderation props
  onRemoveDraft: MouseEventHandler<HTMLElement>;
  onPrevLoad: Function;
  onApprove: MouseEventHandler<HTMLElement>;
  onDecline: MouseEventHandler<HTMLElement>;
}

export type IModerateOnFinishFunc<T> = (values: T, isStartModeration?: boolean) => any;

// for review

export type IModerateReviewRenderFunc = (next: any, prev?: any) => ReactNode;

// for api

export interface IModerateListLoadParams {
  state: ModerateStatusTypes;
}

export interface IModerateItemLoadParams {
  is_draft: boolean;
}
