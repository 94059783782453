import { App } from 'antd';
import { LayoutContent } from 'features/Layout';
import { getModerateState, ModerateForm, useModerateOnFinish } from 'features/Moderate';
import { IServiceEditFormParams, ServiceType } from 'features/Service';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { promiseMap } from 'utils/promise';
import { useServiceItemApprove, useServiceItemDecline, useServiceItemInfoUpdate, useServiceItemLoad, useServiceItemRemove, useServiceItemStartModeration, useServiceItemUpdate, useServicePrevLoad, useServiceRemoveDraft } from '../../../Service/Service.hook';
import { serviceCRUDMap, serviceFormBackUrl, simpleFormItems } from '../../../Service/Service.model';
import { useLazyStationServiceListLoadQuery, useStationServiceRemoveMutation } from '../../../StationService/StationService.api';
import { ISimple } from '../../Simple.interface';
import { SimpleForm } from '../SimpleForm/SimpleForm';

export function SimpleEdit() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { slug, status } = useParams<IServiceEditFormParams>();
  const config = serviceCRUDMap[ServiceType.Simple];
  const { data, isLoading } = useServiceItemLoad(ServiceType.Simple, slug, getModerateState(status));
  const [serviceUpdate] = useServiceItemUpdate(ServiceType.Simple);
  const [serviceStartModeration] = useServiceItemStartModeration(ServiceType.Simple);
  const [serviceInfoUpdate] = useServiceItemInfoUpdate(ServiceType.Simple);
  const [stationListLoad] = useLazyStationServiceListLoadQuery();
  const [stationServiceRemove] = useStationServiceRemoveMutation();
  const [simpleApprove] = useServiceItemApprove(ServiceType.Simple);
  const [simpleDecline] = useServiceItemDecline(ServiceType.Simple);
  const [simpleRemoveDraft] = useServiceRemoveDraft(ServiceType.Simple);
  const [simpleRemove] = useServiceItemRemove(ServiceType.Simple);
  const [prevLoad] = useServicePrevLoad(ServiceType.Simple);
  const backUrl = useMemo(() => serviceFormBackUrl(ServiceType.Simple), []);

  const handlePrevLoad = useCallback(() => {
    return prevLoad({
      slug,
      is_draft: false,
    });
  }, [prevLoad, slug]);

  const handleFinish = useCallback(async (values: ISimple) => {
    const { info, ...base } = values;
    await serviceUpdate(base).unwrap();
    await serviceInfoUpdate({
      slug,
      ...info,
    }).unwrap();
  }, [serviceInfoUpdate, serviceUpdate, slug]);

  const handleOnStartModeration = useCallback(() => {
    return serviceStartModeration(data.slug).unwrap();
  }, [data, serviceStartModeration]);

  const handleModerateFinish = useModerateOnFinish(handleFinish, handleOnStartModeration, backUrl, config.labelEditSuccess);

  const handleRemove = useCallback(async () => {
    // remove related services on stations
    const list = await stationListLoad({
      service_id: data.id,
      service_type: ServiceType.Simple,
    }).unwrap();
    await promiseMap(list, (item) => stationServiceRemove(item.id).unwrap());
    // remove service
    await simpleRemove(data.slug).unwrap();
    message.success('Услуга удалена');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, simpleRemove, stationListLoad, stationServiceRemove]);

  const handleRemoveDraft = useCallback(async () => {
    await simpleRemoveDraft(data.slug).unwrap();
    message.success('Черновик удален');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, simpleRemoveDraft]);

  const handleApprove = useCallback(async () => {
    await simpleApprove(data.slug).unwrap();
    message.success('Изменения приняты');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, simpleApprove]);

  const handleDecline = useCallback(async () => {
    await simpleDecline(data.slug).unwrap();
    message.success('Изменения отклонены');
    navigate(backUrl);
  }, [backUrl, data, message, navigate, simpleDecline]);

  return (
    <LayoutContent
      title={config.labelEditTitle}
      backUrl="/services"
      isLoading={isLoading}
      render={() => (
        <ModerateForm
          status={status}
          onApprove={handleApprove}
          onDecline={handleDecline}
          onRemove={handleRemove}
          onRemoveDraft={handleRemoveDraft}
          onFinish={handleModerateFinish}
          onPrevLoad={handlePrevLoad}
          model={data}
          items={simpleFormItems}
          component={SimpleForm}
        />
      )}
    />
  );
}
